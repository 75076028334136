import { locale } from '@utils/config';
import {
  type StockProps,
  type StockStatus,
  StockStatusEnum,
} from './Stock.types';
import { CartItem } from '@business/gql/graphql';
import { Variant } from '@ts/variant';
import { Product } from '@ts/product';

export const OVERSELLABLE_ARTICLE_STATUSES = ['backorder_item'];

export const isProductOversellable = (item: Product | Variant) => {
  if (item.type === 'Product') {
    return item.flags?.some((flag) =>
      OVERSELLABLE_ARTICLE_STATUSES.includes(flag),
    );
  }
  return OVERSELLABLE_ARTICLE_STATUSES.includes(item.articleStatus);
};
export const isItemOversellable = (item: CartItem) => {
  if (!item.extras?.stock_status) return false;
  return OVERSELLABLE_ARTICLE_STATUSES.includes(item.extras.stock_status);
};

/**
 * To be used in conjunction with stock watch in cart and/or checkout
 * This is a shallow check with (maybe???) outdated stock numbers
 * Final stock calculation will be performed in checkout whenever changes are made
 * @param item /* Cart item
 * @returns boolean
 */

export const isCheckoutDisabled = (item: CartItem) => {
  if (isItemOversellable(item)) {
    return false;
  }
  const quantity = item.stockStatus?.quantity;
  if (typeof quantity !== 'number') return false;
  return item.quantity >= quantity;
};

export function getStockStatus({
  item,
  type,
}: StockProps): StockStatus | undefined {
  if (!item) return;
  const formatter = new Intl.DateTimeFormat(locale, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
  switch (type) {
    case 'cart':
    case 'checkout': {
      const { stockStatus } = item;
      switch (stockStatus?.statusCode) {
        case 'StockItem': {
          const { quantity } = stockStatus;
          if (typeof quantity === 'number') {
            if (quantity > 0) {
              return {
                code: StockStatusEnum.IN_STOCK_ITEM,
                quantity: quantity,
                pretty: quantity.toString(),
              };
            }
            return {
              code: StockStatusEnum.SOLD_OUT_ITEM,
            };
          }
          return {
            code: StockStatusEnum.IN_STOCK_ITEM,
            quantity: Infinity,
            pretty: '10+',
          };
        }
        case 'DeliveryItem': {
          const { nextDelivery /* deliveryTimeDays */ } = stockStatus;
          if (nextDelivery?.date && nextDelivery?.quantity) {
            const date = new Date(nextDelivery.date);
            if (date.getTime() > new Date().getTime()) {
              return {
                code: StockStatusEnum.INCOMING_ITEM,
                quantity: nextDelivery.quantity,
                pretty: nextDelivery.quantity.toString(),
                date: formatter.format(date),
              };
            }
          }
          /**
           * We might want to keep this in case we need delivery estimates in cart/checkout
           *
           * if (deliveryTimeDays) {
           *     const date = new Date();
           *     date.setDate(date.getDate() + deliveryTime);
           *     return {
           *         code: StockStatusEnum.DELIVERY_ITEM,
           *         date: formatter.format(date),
           *     };
           * }
           *
           */
          return {
            code: StockStatusEnum.DELIVERY_ITEM,
          };
        }
        case 'OutOfStock': {
          return {
            code: StockStatusEnum.OUT_OF_STOCK_ITEM,
          };
        }
        case 'PendingSoldOut': {
          const { quantity } = stockStatus;
          if (typeof quantity === 'number') {
            if (quantity > 0) {
              return {
                code: StockStatusEnum.LIMITED_STOCK_ITEM,
                quantity: quantity,
                pretty: quantity.toString(),
              };
            }
            return {
              code: StockStatusEnum.OUT_OF_STOCK_ITEM,
            };
          }
          return {
            code: StockStatusEnum.LIMITED_STOCK_ITEM,
            quantity: Infinity,
            pretty: '10+',
          };
        }
        case 'SoldOut': {
          return {
            code: StockStatusEnum.SOLD_OUT_ITEM,
          };
        }
        default: {
          return {
            code: StockStatusEnum.UNKNOWN,
          };
        }
      }
    }
    case 'plp': {
      const { stockStatus, nextDeliveryDate } = item;
      switch (stockStatus) {
        case 'StockItem': {
          return {
            code: StockStatusEnum.IN_STOCK_ITEM,
            quantity: Infinity,
            pretty: '10+',
          };
        }
        case 'DeliveryItem': {
          return {
            code: StockStatusEnum.DELIVERY_ITEM,
          };
        }
        case 'OutOfStock': {
          if (typeof nextDeliveryDate === 'string' && nextDeliveryDate.length) {
            const date = new Date(nextDeliveryDate);
            if (date.getTime() > new Date().getTime()) {
              return {
                code: StockStatusEnum.INCOMING_ITEM,
                date: '',
              };
            }
          }
          return {
            code: StockStatusEnum.OUT_OF_STOCK_ITEM,
          };
        }
        case 'PendingSoldOut': {
          return {
            code: StockStatusEnum.LIMITED_STOCK_ITEM,
            quantity: Infinity,
            pretty: '10+',
          };
        }
        case 'SoldOut': {
          return {
            code: StockStatusEnum.SOLD_OUT_ITEM,
          };
        }
        default: {
          return {
            code: StockStatusEnum.UNKNOWN,
          };
        }
      }
    }
    case 'pdp': {
      const {
        stockStatus,
        stockQuantity,
        deliveryTimeDays,
        nextDeliveryDate,
        // nextDeliveryQuantity,
      } = item;
      switch (stockStatus) {
        case 'StockItem': {
          const deliveryDate = new Date();
          if (deliveryTimeDays) {
            deliveryDate.setDate(deliveryDate.getDate() + deliveryTimeDays);
          }
          if (typeof stockQuantity === 'number') {
            if (stockQuantity > 0) {
              return {
                code: StockStatusEnum.IN_STOCK_ITEM,
                date: formatter.format(deliveryDate),
                quantity: stockQuantity,
                pretty: stockQuantity.toString(),
              };
            }
            return {
              code: StockStatusEnum.SOLD_OUT_ITEM,
            };
          }
          return {
            code: StockStatusEnum.IN_STOCK_ITEM,
            quantity: Infinity,
            date: formatter.format(deliveryDate),
            pretty: '10+',
          };
        }
        case 'DeliveryItem': {
          if (nextDeliveryDate) {
            const date = new Date(nextDeliveryDate);
            if (date.getTime() > new Date().getTime()) {
              return {
                code: StockStatusEnum.INCOMING_ITEM,
                date: formatter.format(date),
              };
            }
          }
          if (deliveryTimeDays) {
            const date = new Date();
            date.setDate(date.getDate() + deliveryTimeDays);
            return {
              code: StockStatusEnum.DELIVERY_ITEM,
              date: formatter.format(date),
            };
          }
          return {
            code: StockStatusEnum.DELIVERY_ITEM,
          };
        }
        case 'OutOfStock': {
          if (nextDeliveryDate) {
            const date = new Date(nextDeliveryDate);
            if (date.getTime() > new Date().getTime()) {
              return {
                code: StockStatusEnum.INCOMING_ITEM,
                date: formatter.format(date),
              };
            }
          }
          return {
            code: StockStatusEnum.OUT_OF_STOCK_ITEM,
          };
        }
        case 'PendingSoldOut': {
          if (typeof stockQuantity === 'number') {
            if (stockQuantity > 0) {
              return {
                code: StockStatusEnum.LIMITED_STOCK_ITEM,
                quantity: stockQuantity,
                pretty: stockQuantity.toString(),
              };
            }
            return {
              code: StockStatusEnum.OUT_OF_STOCK_ITEM,
            };
          }
          return {
            code: StockStatusEnum.LIMITED_STOCK_ITEM,
            quantity: Infinity,
            pretty: '10+',
          };
        }
        case 'SoldOut': {
          return {
            code: StockStatusEnum.SOLD_OUT_ITEM,
          };
        }
        default: {
          return {
            code: StockStatusEnum.UNKNOWN,
          };
        }
      }
    }
    default: {
      return {
        code: StockStatusEnum.UNKNOWN,
      };
    }
  }
}
