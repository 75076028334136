import priceFormatter from './priceFormatter';
import { locale as _locale } from '@utils/config';

export const formatPrice = (
  price: number | null | undefined,
  desiredFormat: 'whole' | 'fixed' | undefined = 'whole',
  allowZero: boolean = false,
) => {
  if (!allowZero && (!price || price <= 0)) {
    return '-';
  }

  let priceWithFallback = price ?? 0;

  let locale = _locale;

  if (locale === 'nb-no') {
    locale = 'sv-se';
  }

  const isWhole = priceWithFallback % 1.0 === 0; // has no decimal part
  const formatter = priceFormatter(
    locale,
    isWhole && desiredFormat === 'whole' ? 'whole' : 'fixed',
  );

  return formatter.format(priceWithFallback);
};
